@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.stewarttitle-page-container {
    font-family: 'Poppins', sans-serif;
    margin: 10px;
}

.st-main-title {
    font-size: 24px !important;
    text-align: center;
    font-weight: 700 !important;
    color: rgb(86, 198, 199, 1) !important;
}

.textbox-radius {
    border-radius: 10px;
}

.sub-title {
    text-align: center;
    font-weight: 600;
}

.main-heading {
    font-size: 20px !important;
    font-weight: 700 !important;
    color: rgb(0, 73, 137);
}

.st-main-heading-subtitle {

    font-size: 18px !important;
    font-weight: 700 !important;
    color: rgb(0, 73, 137);
}



.sub-heading {
    font-size: 16px;
    font-weight: 700;
    color: rgb(0 73 137);
}

.grid-new-row {
    padding-top: 10px;
}

.st-selectfield {
    width: 30ch;
}

.st-datepicker {
    margin-left: 1;
    margin-right: 1;
    width: 200,
}

.st-grid-item__toggle {
    padding-top: 10px;
    font-family: 'Poppins', sans-serif;
}

.st-checkbox-label {
    width: 100%;
    color: rgb(0, 73, 137);
    padding-left: 5px;
    padding-top: 30px;
}

.stewarttitle-page-container .new-row {
    padding-top: 10px;
}

.stewarttitle-page-container .straight-row {
    display: flex;
    padding: 5px 20px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.stewarttitle-page-container .paddingleft20 {
    padding-left: 20px;
}

.stewarttitle-page-container .aligned {
    display: flex;
    padding: 0px 20px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.hide-content {
    display: none;
}

.stewarttitle-checkbox-error {
    display: inline-flex;
    align-items: center;
}

.stewarttitle-helpertext-error {
    font-size: 26px !important;
    margin-right: 4px !important;
    margin-top: 10px !important;
}

.stewarttitle-disabled {
    color: black !important;
    font-weight: bold !important;

}

.stewarttitle-disabled .ms-label {
    color: black !important;
    font-weight: bold !important;

}

.st-date {
    padding-top: 44px !important;
    width: 35ch;
}

.st-select-label {
    padding-bottom: 15px !important;
}

.st-typo__heading {
    color: rgb(0, 73, 137);
    font-style: bold;
}

.st_datepicker {
    .ms-TextField-fieldGroup {
        height: 55px !important;
    }
}

.st_radios,
.st_checkboxes {
    padding-left: 5px;
}

.st_tandc {
    padding-top: 10px;
    padding-left: 5px;
    font-weight: 700 !important;
}

.st_create-cheque-button {
    font-family: 'Poppins', sans-serif;
}

.st-textfield {
    width: 33ch;
}

@media (min-width: 850px) and (max-width: 1000px) {
    .st-textfield {
        width: 29ch;
    }

    .st-selectfield {
        width: 27ch;
    }
}

@media (max-width: 849px) {
    .st-textfield {
        width: auto;
    }

    .st-selectfield {
        width: auto;
    }
}