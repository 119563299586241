
.create-order-form {
    margin: 10px 40px 10px 40px;
    padding: 30px;
    background: #FFFFFF;
    font-family: Roboto Helvetica Arial sans-serif;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.22);
 
}

.header {
    font-size: 25px;
    text-align: center;
    font-weight: 700;    
}

.ecaf-data-list {
    margin: auto;
}

.ecaf_addtional_instructions textarea{
    min-height:120px;
}

.send-to-ecaf-tr{
    text-align:center;
    width:120px;
}

.ecaf-data-key,.ecaf-data-value{
    padding-bottom:15px;
}

.remove-btn {
    float: right;
    margin-right: 10px;
    text-transform: capitalize !important;
}
.add-btn {
    margin-top: -30px !important;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 40px;
    text-transform: capitalize !important;
}
.section {
    margin:30px 5px 30px 5px;

}
.section-name {
    font-size: 18px;
    font-weight: 600; 
    font-family: Roboto Helvetica Arial sans-serif;
    margin-bottom: 15px;
}
.sub-section {
    margin: 20px 0 20px 10px;
    font-size: 16px;
    font-weight: 600;
    font-family: Roboto Helvetica Arial sans-serif;
    text-transform: capitalize !important;
}

.flex-form {
    flex-grow: 1;  
}

.transaction-instruction {   
}

.transaction-date {
    font-size: 14px !important;
    margin-left: 20px !important;
    font-weight: 600 !important;
}

.transaction-types {
    margin:10px 0 10px 0;
}

.text-field {
    font-size: 14px !important;
    width: 90%;
}

.create-order-btn {
    font-size: 14px !important;
    float: right !important;
    margin-top: 20px !important;
    margin-right: 20px !important;
}

.table-row {
    display:table-row;
}

.table-cell {
    display: table-cell;
    width: 33%;
}
.cell-left{
    text-align:left;
}
.cell-center {
    text-align: center;
}
.cell-right {
    text-align: right;
}
.img-actionstep {
    max-width: 400px;
    width: 100%;
    height: auto;
}
.img-livesign {
    max-width: 216px;
    width: 100%;
    height: auto;
}
.img-arrow {
    max-width: 300px;
    width: 100%;
    height: auto;
}
.header{
    text-align:center;
}
.loading-section{
    margin:40px 0 100px;
}

.MuiGrid-item.validation-error {
    margin-top: 15px;
}