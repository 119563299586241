#top-frameless-nav {
    background-color: #1b3b6a;
    color: #ffffff;
    height: auto;
    margin: 0;
    padding: 0;
}

#top-frameless-nav a {
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0 1em 0 1em;
    height: 40px;
    color: #ffffff;
}
#top-frameless-nav a:hover {
    background: white;
    color: #1b3b6a;
    text-decoration: none;
}

#top-frameless-nav .align-right {
    text-align: right;
}

#top-nav-links {
    text-align: right;
    padding-top: 1.2em;
}

#top-nav-links a {
    color: black;
    padding: 0 0.5em 0.5em 0.5em;
    font-size: 1.1em;
}

#top-nav-links a img {
    padding-top: 0.6em;
}

.full-page-banner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.full-page-banner-actionstep-logo,
.full-page-banner-konekta-logo {
    height: 30px;
    padding: 8px 0 8px 0;
}

.full-page-banner-actionstep-logo { padding-right: 10px }
.full-page-banner-konekta-logo { padding-left: 15px; }

#page-header {
    border-bottom: 1px solid rgba(96,125,139,0.2);
    margin: 0;
    padding-bottom: 1em;
}