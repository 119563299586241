/* RPI */
/* body {
    font-family: 'Poppins';
} */
.txtheader {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
    font-family: 'Poppins', Arial, sans-serif;
}

.formparagraph {
    margin-left: 10%;
    margin-right: 10%;
    font-size: larger;
    font-family: 'Poppins', Arial, sans-serif;
}

.text-field {
    height: 34px;
}

.maindiv {
    align-items: center;
    width: 628px;
    height: 89px;
    margin-left: 4%;
}

.txtinput {
    margin-left: 47px;
    padding-top: 6px;
}

.setplace {
    padding-top: 15px;
}

.lblset {
    margin-left: 49px;
    padding-top: 9px;
    color: #004989;
    font-weight: 400;
    font-family: 'Poppins', Arial, sans-serif;
}


.setdivarea {
    background-color: #E5E5E5;
    height: auto;
    margin-left: 15%;
    margin-right: 18%;
}

.chkboxAdjust {
    margin-top: 9px;
}

.btncontroladdgap {
    /* padding-top: 82px; */
    text-align: center;
    padding-bottom: 15px;
    margin-left: 90px;
}

.btncontrolremovegap {
    text-align: center;
    padding-bottom: 15px;
    margin-left: 90px;
}

.btnstyle {
    background-color: #5555d1;
    color: whitesmoke;

}

.txtbackgroundolor {
    background-color: white;
    height: 40px;
    border-radius: 4px;
}
.reffereldiv{
    font-size: 19px;
    font-weight: bold;
    color: #004989;
    /* margin-left: 19px; */
    padding-top: 15px;
}
.lblsetservice{
    padding-top: 7px;
    margin-left: 49px;
    font-size: large;
    color: #004989;
    font-weight: 400;
}
.titlespan{
    margin-left: 12px;
}
.headdeclaimer{
    color: rgb(246, 65, 65);
    font-family: "'Poppins', Arial, sans-serif"
}
.declamercontent{
    /* padding-top: 75px; */
    margin-left: 71px;
    margin-right: 52px;
    font-family: 'Poppins', Arial, sans-serif;
}

.confirmationdivset {
    color: #004989;
    font-weight: bold;
    margin-top: 11px;
    font-size: larger;
    border-bottom-style: solid;
    border-bottom-width: thin;
}

.divconfiormation{
    margin-top: 80px;
}

.divconfiormationspan{
    margin-top: 60px;
}
.confirmationmessage{
    text-align: left;
}
.fontstyle{
    font-weight: bold;
    font-style: italic;
    font-size: large;
}
.msgfontstyme{
    font-size: larger;
}
.txtmessage{
    margin-top: 11px;
    font-size: larger;
}
.txterror{
    font-style: italic;
    color:#F83E3E;
}
.failederrormessage{
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: bold;
    margin-top: 11px;
    font-size: larger;
    background-color:#F83E3E;
    height: 50px;
    border-radius: 3px;
}

.errorparagraf{
    padding-top: 15px
}
.buttonplacecenter{
    margin-right: 17px;
    margin-top: 60px;
    margin-left: 77%;
}

.buttonsize{
    height: 53px;
    width: 217px;
    border-radius: 8px;
    font-size: large;
    padding-top: 10px;
}
.lblsetserviceerror{
    padding-top: 7px;
    margin-left: 49px;
    font-size: smaller;
    color:#F83E3E;
}
.emailerror{
    margin-left: 10px;
    font-size: x-small;
    color: #f44336;
    font-weight: 600;
}
.failederror{
    padding-top: 15px;
}
.radiobtn{
    font-size: smaller;
    margin-left: 55px;
    color: #f44336;
}
.optionrequire{
    margin-top: 10px;
}

 /* Use these css for desable mouse hover icon on radio button and checkbox */
.MuiIconButton-colorSecondary:hover {
    background-color: transparent !important;
}
.MuiCheckbox-colorPrimary.Mui-checked:hover {
    background-color:transparent !important;
}
.MuiIconButton-colorPrimary:hover {
    background-color: transparent !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
    color: #004989 !important;
}