@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Exo:300,400,600,700,900");

body {
    font-family: "Exo", "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #333;
    /* overflow-x: hidden; */
}

html,
body {
    height: 100%;
    margin: 0px;
}

#root {
    height: 100%;
}

.rootDiv{
    width: 100%;
    height: 100%;
}
.rootDivEmbededIframe {
    overflow: hidden ;
}
.normal-page-wrapper {
    min-height: 100%;
    height: 100%;
    max-width: 1020px;
    /* overflow-x: hidden; */
    margin: auto;
    padding-left: 10px;

    display: flex;
    flex-direction: column;
}

.full-page-wrapper,
.full-page-wrapper > div {
    width: 100%;
    height: 100%;
}

.full-page-wrapper div > div {
    height: calc(100% - 46px);
    padding: 0;
    margin: 0;
}

/* only the contact us page with the class trigger this css */
.full-page-wrapper > div:has(.contact-us-page) {
    height: auto;
    padding: 0;
    margin: 0;
    border: 0;
}

strong { font-weight: 600; }
h1 { font-weight: 500; }
h2 { font-weight: 600; }
h3 { font-weight: 500; }

.vertical-container {
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
}

.wrapper {
    padding: 40px 40px 100px;
}

/* ERROR BORDER */
.error-box {
    border-color: #a3262c;
}

.errorMessage {
    font-size: 12px;
    font-weight: 400;
    color: rgb(164, 38, 44);
    margin: 0;
    padding-top: 5px;
    display: flex;
    align-items: center;
}

/* TEXT COLORS */
.text-info {
    color: #1b3b6a;
}

.text-danger {
    color: #f15a29;
}

/* MODALS */

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
}

.modal-body {
    position: relative;
    padding: 15px;
}
.ms-Modal-scrollableContent, .scrollableContent-157, .scrollableContent-164{
    height: 100% !important;
    max-height: 100% !important;
}

.modal-footer {
    margin-top: 0;
    border: none;
    padding: 10px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

/* ANIMATIONS */

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

fieldset {
    border: none;
    padding-inline-start: unset;
    padding-inline-end: unset;
    margin-inline-start: unset;
    margin-inline-end: unset;
}

.button:not(.is-disabled) {
    background-color: #004989;
    color: #ffffff;
}

.button:not(.is-disabled):hover {
    background-color: #003979;
    color: #ffffff;
}

ul.extra-space li {
    padding-bottom: 1.1em;
}