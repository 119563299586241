.modal-close-button{
    font-weight: bold;
    padding-bottom: 10px;
    float: right;
}
.modal-row-items {
    padding-top: 10px;
    padding-bottom: 10px;
}

.modal-position {
position: absolute;
top: 10px;
right: 100px;
bottom: 0;
left: 0;
z-index: 10040;
overflow: auto;
overflow-y: auto;
}
.modal-header {
    padding-top: 23px;
    padding-left: 10px;
    font-weight: bold;
    font-size: large;
}

.center-align {
    align-items: center;
}
