.invite-participants-modal {
    overflow-y: auto;
    max-height: 100vh;
    min-width: 550px !important;
}

.invite-participants-wrapper {
    padding: 0px !important;
    margin: 0px !important;
    width: 98% !important;
}