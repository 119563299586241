.update-workspace-element-container {
    display: flex;
    align-items: center;
    margin: 15px 0px;
}

.update-workspace-element-container label {
    min-width: 150px;
}

.status-container {
    display: flex;
}