.gx-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.gx-iframe {
    width: 100%;
    height: 100%;
    border: 0;
}