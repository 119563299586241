.tittle {
    text-align: center;
}

.pexa-data-key {
    color: black;
}

.pexa-new-settlement {
    padding: 5px 0px 5px 50px!important;
    color: black;
    font-weight:normal;
}
.pexa-settlement-date-time-confirmation-table {
    border-collapse: collapse;
    width: 80%;
}
.pexa-settlement-date-time-confirmation-table td,
.pexa-settlement-date-time-confirmation-table th {
    border: 1px solid black;    
}