.saveButton {
    float:left;
   background-color:#0acf38 !important;
   color: white !important;
}
.Mui-disabled{
    background-color:#686969 !important;
}

.party-details {
    width: 100%;
    text-align: left;
    line-height: 25px;
    padding-bottom: 15px;
}

.party-details-heading {
    font-size:15px;
}
.MuiRadio-colorSecondary.Mui-checked  {
    color:#004989 !important;
}
.input-invalid {
      border: "2px solid #dc7070 !important";
      border-radius:"6px !important"
}
.userDeatils{
    width: 100% !important;
    background-color: black;
}
  