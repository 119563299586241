.refresh-pexa-container {
    background-color: white;
    max-width: 1020px;
}

.totalarea {
    height: auto;

}

.pexaRefresher-modal-body {
    max-width: 1350px;
}

.fssLineItems-th {
    font-weight: bold !important;
}

.maindivareas {
    align-items: center;
    width: 628px;
    height: 89px;
    display: flex;
}

.fsscontainer {
    position: relative;
}

.mainfssdiv {

    display: flex;
}

.mainfssheader {

    width: 100%;
    height: auto;
    display: flex;
    border-bottom: 1px solid #004989;
}

.btndeal {
    align-items: center;
    text-align: center;
    padding-left: 206px;
}

div#fssLineItems tr:nth-child(even) {
    background-color: whitesmoke;
}

div#dealingNumber tr:nth-child(even) {
    background-color: whitesmoke;
}


.buttonplaces {
    padding-top: 20px;
}

.errormessage {
    margin-top: 35px;
}

.buttonplaces-back {
    padding-top: 30px;
}

.btnplace {
    padding-top: 21px;
    margin-left: 177px;
}

.lbltext {
    padding-top: 9px;
    font-weight: bold;
    color: #004989;
}

.dealingNumberMessage {
    font-weight: bold !important;
}

.Mui-disabled {
    background-color: #ffffff1c !important;
}

.maindivareashead {
    align-items: center;
    width: 100%;
    height: auto;
    display: flex;
}

.maindivareas {
    margin-top: 10px;
    align-items: start;
    width: 100%;
    height: auto;
    display: flex;
}

.lblpexa {
    margin-left: 35%;
    color: #252525;
    font-weight: 400;
    font-family: 'Poppins', Arial, sans-serif;
}

.lblastion {
    color: #252525;
    font-weight: 400;
    font-family: 'Poppins', Arial, sans-serif;
}

.lblset {
    margin-left: 35%;
    padding-top: 9px;
    color: #1C304A;
    font-weight: bold;
    font-family: 'Poppins', Arial, sans-serif;
}

.headertext {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
    font-family: 'Poppins', Arial, sans-serif;
    color: #1b3b6a;
}

.actionstep {
    margin-left: 35;
    padding-top: 9px;
    color: #1C304A;
    font-weight: bold;
    font-family: 'Poppins', Arial, sans-serif;
}

.divfound {
    margin-left: 58px;
}

.divdealnotfound {
    margin-left: 93px;
}

.divfounderror {
    margin-left: 58px;
    color: #F83E3E;
}

.divdealnotfounderror {
    margin-left: 93px;
    color: #F83E3E;
}

.container .btnsize {
    border-radius: 5px;
    width: 185px;
}

.container .fssbtnsize {
    border-radius: 5px;
}

.container .btkbtnsize {
    border-radius: 5px;

}

.divfssitemsheader {
    margin-left: 15%;
    padding-top: 9px;
    color: #1C304A;
    font-weight: bold;
    font-family: 'Poppins', Arial, sans-serif;
}

.divfssitems {
    margin-left: 15%;
    padding-top: 9px;
    color: #1C304A;
    font-family: 'Poppins', Arial, sans-serif;
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .mainfssheader {
        overflow-wrap: break-word;

    }

    .mainfssdiv {
        overflow-wrap: break-word;
    }

    .divfssitems {
        margin-left: 2%;
        border: 1px solid red;
    }

    .divfssitemsheader {
        margin-left: 2%;
        border: 1px solid red;
    }

}