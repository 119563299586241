.email-archiver-table {
    margin: 0px !important;
    width: 100%;
}
.tittle {
    text-align: center;
}

.blank_row {
    height: 40px !important;
    
}

.email-archiver-step {
    padding-top: 2px;
}

.horizontal-container ul li {
    width: 20%;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: left
}
.progressBar {
    height: 10px !important;
 
}
.percentageValue {
    font-size: 0.7rem !important;
}