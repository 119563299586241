input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.settlement-calculator {
    line-height: 20px;
    font-family: 'Poppins', Arial, sans-serif;
}

.settlement-calculator .section {
    margin: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid rgb(235, 235, 235);
}

#settlementBorder {
    border: 1px solid;
    padding: 0px 8px;
}

.settlement-calculator .section-body {
    display: flex;
    flex-direction: column;
}

.settlement-calculator .state-row {
    display: flex;
    padding-bottom: 10px;
    padding-top: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.settlement-calculator .state-row-no-padding {
    display: flex;
    padding-bottom: 0px;
    padding-top: 0px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.settlement-calculator .heading-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 16px;
}

.settlement-calculator .Paye-Cheque-Details {
    margin-left: 150px;
}

.settlement-calculator .settlement-background {
    background-color: #d8dbd9;
}

.settlement-calculator .settlement-border {
    border-width: thick;
    border-color: #d8dbd9;
}

.settlement-calculator .Paye-Amount {
    float: right;
    margin-right: 45px
}

.settlement-calculator .detail-row {
    padding-top: 3px;
    padding-bottom: 3px;
}

.settlement-calculator .detail-row>div {
    display: flex;
}

.settlement-calculator .sa-link {
    padding-top: 10px;
    padding-bottom: 10px;
}

.settlement-calculator .additional-header {
    padding-top: 5px;
    padding-bottom: 5px;
}

.settlement-calculator .left-align-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.settlement-calculator .right-align-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.settlement-calculator .dual-section-header {
    display: flex;
    justify-content: space-between;
}

.settlement-calculator .right-align {
    text-align: right;
}

.content-right-align{
    text-align: right;
}

.settlement-calculator .center-align {
    text-align: center;
}

.settlement-calculator .button {
    margin: 3px;
    border-radius: 3px;
    color: white;
    border: none;
}

.settlement-calculator .gray-button {
    background-color: gray;
    padding: 10px;
}

.settlement-calculator .red-button {
    background-color: rgb(212, 73, 70);
    padding: 10px;
}

.settlement-calculator .blue-icon-button {
    background-color: rgb(0, 120, 212);
    padding: 25px;
}

.settlement-calculator .rounded-blue-icon-button {
    background-color: rgb(0, 120, 212);
    padding: 20px;
    border-radius: 15px;
}

.settlement-calculator .topaz-blue-icon-button {
    background-color: white;
    border-radius: 15px;
    border: 2px solid;
    border-color: rgb(8, 174, 176);
    padding: 18px;
    color: rgb(8, 174, 176);
}

.settlement-calculator .show-hide-button {
    background-color: rgb(0, 120, 212);
    padding: 10px;
    margin-left: 10px;
}

.settlement-calculator .green-icon-button {
    background-color: rgb(82, 175, 82);
    padding: 25px;
}

.settlement-calculator .price-info {
    height: 100%;
    vertical-align: middle;
    text-align: right;
}

.settlement-calculator .separator {
    text-align: right;
    margin-left: 8px;
    margin-bottom: -10px;
}

.settlement-calculator .calculated {
    text-align: right;
    margin-left: 8px;
    margin-top: 15px;
}

.settlement-calculator .modal {
    border-radius: 5px;
}

.settlement-calculator .modal-title {
    font-weight: 600;
    font-size: 15px;
    color: black;
}

.settlement-calculator .modal-row {
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.settlement-calculator .modal-label {
    min-width: 200px;
}

.settlement-calculator .modal-close-button {
    position: absolute;
    right: 10px;
    top: 10px;
}

.content-right-align {
    text-align: right;
}

.settlement-calculator .modal-body {
    min-width: 700px;
}

.settlement-calculator .loading-modal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: "Exo", "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.settlement-calculator .create-cheque-button {
    margin: 10px;
    margin-left: 25px;
}

.settlement-calculator .red {
    color: red;
}

.settlement-calculator .green {
    color: green;
}

.settlement-calculator .blue {
    color: blue;
}

.settlement-calculator .ms-Checkbox-checkbox {
    display: none;
}

.settlement-calculator .ibox-footer {
    display: flex;
}

.settlement-calculator .update-check-box {
    margin: 0;
    margin-top: 5px;
    margin-left: 5px;
}

.settlement-calculator .detail-row>.ms-Grid-col {
    display: unset;
}

.settlement-calculator .ibox-content {
    min-height: 0;
}

.loading-widget {
    text-align: center;
    padding: 3em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

/* ibox (from old styles, this is to be removed and components replaced with fabric UI components */

.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
    -webkit-box-shadow: 0 3px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
    box-shadow: 0 3px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}

.ibox-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #fff;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 2px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 0px 15px;
    min-height: 48px;
}

.ibox-content {
    clear: both;
    background-color: #fff;
    color: inherit;
    padding: 15px 20px 20px 20px;
    min-height: 150px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
}

.ibox-footer {
    color: inherit;
    border-top: 1px solid #e7eaec;
    font-size: 90%;
    background: #ffffff;
    padding: 10px 15px;
}

.settlement-calculator-beta {
    background-color: #F83E3E !important;
}

.settlement-calculator-beta-list {
    color: #F83E3E;
}

.settlement-calculator-beta-title {
    margin-left: -16px;
}
.SettlementStatementBorder{
    border: solid;
    border-width: 0px 1px 1px 1px;
    padding: 10px;
}
#settlementThreeBorder{
    border: solid 1px;
    border-bottom: 0px;
}

/* #totalFundRequiredForSettlement{
    width: 350px;
} */

.settlement-calculator .margin-top20 {
    margin-top: -20px;
}

.header-text {
    color: #08AEB0;
}

.align-right {
    text-align: right;
}

.mui-font-override .MuiTypography-root {
    font-family: "Exo", "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 1em !important;
}

.adjustment-header-text {
    font-size: 15px;
    margin-left: 20px;
    font-weight: bold;;
}

.adjustment-header {
    border-bottom: 1px solid rgb(235, 235, 235);
}