.matter-page {
  padding: 0px 10px;
}

.parter-cards-wrapper {
  margin: 30px 30px !important;
}

.parter-cards-wrapper > div.ms-Grid-col {
  float: left;
}

.partner-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  border-bottom: 1px solid #efefef;
}

.partner-logo.pexa-logo {
  position: relative;
}

.partner-card {
  min-height: 360px;
  margin: 20px 15px;
}

.partner-integrations {
  padding: 5px;
}

.partner-integrations a {
  text-align: center;
  text-decoration: none;
}

.partner-integrations a:hover {
  text-decoration: underline;
}

.partner-integrations a:not(.is-disabled) {
  color: black;
}

.error-page {
  margin: 30px 0px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.integration-link-disable {
  color: currentColor;
  pointer-events: none;
  opacity: 0.5;
  text-decoration: none;
}
