.document-list-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3px;
}
.doc-header {
    font-weight: bold;
    font-size: 15px !important;
    padding-bottom: 15px;
}

.doc-row {
display: inline-block !important;
font-size: 1rem;
}
.doc-row span {
    padding-left:10px;

}
.doc-return-button{
    width:75% !important;   
}