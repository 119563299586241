input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.topaz-blue-icon-button {
    background-color: white;
    border-radius: 15px;
    border: 2px solid;
    border-color: rgb(8, 174, 176);
    padding: 18px;
    color: rgb(8, 174, 176);
}

.settlement-calculator {
    line-height: 20px;
    font-family: 'Poppins', Arial, sans-serif;
}

.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
    -webkit-box-shadow: 0 3px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
    box-shadow: 0 3px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}

.ibox-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #fff;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 2px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 0px 15px;
    min-height: 48px;
}

.ibox-content {
    clear: both;
    background-color: #fff;
    color: inherit;
    padding: 15px 20px 0px 20px;
    min-height: 150px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
}

.ibox-footers {
    color: inherit;
    /* border-top: 1px solid #e7eaec; */
    font-size: 90%;
    background: #ffffff;
    padding: 5px 25px 10px;
    display: flex;
    justify-content: end;
}

.modal-row {
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.calculationcenter {
    text-align: center;
}

.calculationRight {
    text-align: right;
}

.display-error {
    color: inherit;
    font-size: 90%;
    background: #ffffff;
    padding: 5px 250px 0px;
    display: flex;
}

.error-red-border {
    border: 1px solid red !important;
}