.konekta-footer {
    border-top: 1px solid #e7eace;
    padding-top: 3em;
}

.konekta-footer-text {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.konekta-footer-logos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 4em;
}

.integration-partner-logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 60%;
}

.integration-partner-logos img {
    width: 120px;
    margin: 1em;
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.integration-partner-logos img:hover {
    filter: none;
    -moz-filter: none;
    -webkit-filter: none;
    cursor: pointer;
}