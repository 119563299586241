.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
}

.btn-success {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
}

.btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
}

.btn-primary {
    color: #fff;
    background-color: #1b3b6a;
    border-color: #1b3b6a;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.customer-wrapper{
    margin-bottom:30px;
}

.customer-wrapper .voi-review-status {
    display:inline-block;
    padding:5px 10px;
    background-color: #0dcaf0
}

.counter-sign-status {
    display: inline-block;
    padding: 5px 10px;
    background-color: #0dcaf0
}

.status-shape {
    width: auto !important;
    height: auto;
    display: inline-block;
    padding: 7px;
    border-radius:25px;
} 
.small-text{
    color:red;
}

.btn-countersign {
    display: block !important;
}
.btn-download-caf-Finalised {
    width: 100% !important;
    background-color: rgb(19, 78, 19) !important;
}
.caf-finalised-padding {
    padding-bottom: 20px;
}
.vertical-container {
    width: 99% !important;    
}
