.ft-container {
    font-family: "Exo", "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.firsttitle-page.ms-Grid-row {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ebebeb;
}

.firsttitle-page.ms-Grid-row:last-child {
    border-bottom: 0px;
}

.firsttitle-page.row.wrapper {
    padding: 40px 40px 40px;
}