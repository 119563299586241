.announcement-header {
    font-size: 14px !important;
    width: 100%;
}

.promotion-announcement {
    background-color: #224D86 !important;
}

.promotion-new-product {
    background-color: #74C3BB !important;
}

.promotion-new-product * {
    color: #000000 !important;
}

.promotion-warning {
    background-color: #E64C52 !important;
}

/* this is to allow aligning the text inside of the promotion message bar, overriding an office ui styling */
.ms-MessageBar-text {
    display: block;
}
/* as the background color is konekta blue, all the text within it should be defaulted to white */
.announcement-header div, 
.announcement-header a, 
.announcement-header span, 
.announcement-header sp {
    color: #ffffff;
}
.announcement-header-margin {
    margin-left: 22px;  
    margin-right: 22px;
}
.announcement-heading {
 font-size: 14px;
 font-weight: bold;
}
.announcement-body {
font-size:12px !important;
padding-top:4px;
}