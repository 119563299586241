.legalaid-body {
    line-height: 20px;
    font-family: 'Poppins', Arial, sans-serif;
}

.legalaid-body>button {
    margin: 10px;

}

.legalaid-search-container {
    padding-top: 20px;
}

.heading-grid {
    padding-left: 350px;
}

.dateRange-grid {
    padding-top: 50px;
    padding-left: 200px;
}

.textFileSizeLarge {
    width: 100%;
}

.cellWidthSmall {
    width: 10%
}

.textFileSizMedium {
    width: 100%;
}

.detailsTableHead {
    padding: 10px;
    width: 40%;
    background-color: #a9a4a466;
}

.detailsTableBody {
    border: 1px solid black;
}

.detailsTableCell {
    border: 1px solid black;
}

.detailsHeading {
    font-weight: bold;

}


.detailsTableSubHeading {
    padding-top: 10px;
}

.padding-grid {
    padding-top: 15px;
    padding-bottom: 30px;
}