.ecaf-auth-section{
    margin:auto;
}

.ecaf-auth-row {
    max-width: 360px;
    margin: 10px 0;
}

.back-to-home {
    text-decoration: underline;
    color: #134794;
    padding-left: 10px;
}

.ecaf-auth-wrapper{
    text-align:center;
}