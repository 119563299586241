/*add comment*/
.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
}

.btn-success {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
}

.btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
}

.btn-primary {
    color: #fff;
    background-color: #1b3b6a;
    border-color: #1b3b6a;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.customer-wrapper {
    margin-bottom: 30px;
}

.customer-wrapper .voi-review-status {
    display: inline-block;
    padding: 5px 10px;
    background-color: #0dcaf0
}

.counter-sign-status {
    display: inline-block;
    padding: 5px 10px;
    background-color: #0dcaf0
}

.small-text {
    color: red;
}

.order-link-wrapper {
    height: 30px;
}

a {
    text-decoration: underline;
    text-decoration-color: rgb(0, 73, 137);
}

a.order-link:hover {
    font-weight: 600;
}

.order-link-wrapper .order-link {
    text-decoration: none;
}

.order-link-wrapper .order-link {
    text-decoration: underline;
    text-decoration-color: rgb(0, 73, 137);
}

.orderlist-table .row-wrapper {
    height: 35px;
}

.orderlist-table .row-wrapper .order-id {
    text-align: left;
    color: rgb(0, 73, 137);
}

.customer-name {
    color: #333;
}

.order-link-status {
    text-decoration: none !important;
    color: white !important;
}

a.order-link-status:hover {
    font-weight: 600;
}

.orderlist-table .row-wrapper td {
    text-align: center;
}

.orderlist-table .cell-order-id {
    text-align: left;
}

.status-shape-orderlist {
    width: 65%;
    height: auto;
    display: inline-block;
    padding: 7px;
    border-radius: 25px;
}

.status-Draft {
    color: white;
    background: rgb(182, 180, 180);
}

.status-SentToClient, .status-NotStarted {
    color: white;
    background: rgb(220, 87, 36);
}

.status-InProgress, .status-NotReviewed {
    color: white;
    background: rgb(52, 122, 193, 0.97);
}

.status-Finalised, .status-Approved {
    color: white;
    background: rgb(19, 126, 19);
}

.status-RequiresReview, .status-Rejected {
    color: white;
    background: rgb(220, 87, 36)
}

.status-ReadyToCountersign {
    color: white;
    background: rgb(52, 122, 193, 0.97);
}

.status-Created {
    color: white;
    background: rgb(182, 180, 180);
}

.cell-customers {
    text-align: left;
}

.edit-order-modal {
    min-width: 70%;
    max-width: 90%;
    min-height: 10%;
    max-height: 90%;
    text-align: center
}

.view-customer-modal {
    min-width: 90%;
    max-width: 90%;
    min-height: 10%;
    max-height: 90%;
    text-align: center
}

.modal-position {
    height: 70% !important;
}

.btn-danger {
    background-color: '#f00';
    color: '#fff'
}
.modal-header {
    padding-top: 20px;
    padding-left: 10px;
    font-weight: bold;
    font-size: large;
}

.envelope-table
{
    margin-bottom: 10px;
}

.customer-table
{
    padding: 10px;
}

.envelope-block
{
    margin-bottom: 10px;
    border-bottom: 1px solid black;
}

.left-aligned-text
{
    text-align: left;
}

.center-aligned
{
    display: block;
    margin: auto;
}

.text-center
{
    text-align: center;
}

.app-font *
{
    font-family: Exo, 'Open Sans', Helvetica, Arial, sans-serif;
}