.workspace-summary-modal {
    width: 700px;
    overflow-y: hidden;
    max-height: 100vh;
}

.workspace-summary-modal-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
}

.workspace-summary-tr > td {
    padding: 5px 1px 5px 15px;
}

.workspace-summary-wrapper {
    padding: 0px !important;
    margin: 0px !important;
    width: 100%;
}